import Testimonial from "../../../../src/components/Testimonial";
import { roadmapService } from "../../../../data/SiteConfig";
import { printGbpWithUsd } from "../../../../src/utils/formatting";
import ContactForm from "../../../../src/components/ContactForm";
import SimpleFaqList from "../../../../src/components/SimpleFaqList";
import * as React from 'react';
export default {
  Testimonial,
  roadmapService,
  printGbpWithUsd,
  ContactForm,
  SimpleFaqList,
  React
};