import { guidanceService } from "../../../../data/SiteConfig";
import { printGbp, printUsd, gbpToUsd } from "../../../../src/utils/formatting";
import ContactForm from "../../../../src/components/ContactForm";
import Testimonial from "../../../../src/components/Testimonial";
import * as React from 'react';
export default {
  guidanceService,
  printGbp,
  printUsd,
  gbpToUsd,
  ContactForm,
  Testimonial,
  React
};