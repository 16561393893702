import React from 'react';

export default function Testimonial({
  children,
  authorName,
  authorTitle,
  authorPhotoUrl,
  hideBigQuote,
}) {
  return (
    <blockquote className="my-6 md:flex-grow md:flex md:flex-col bg-primary-50 px-4 pt-8 pb-12 md:px-8 border border-primary-100">
      <div className="relative font-medium md:flex-grow">
        {!hideBigQuote && (
          <svg
            className="h-8 w-8 text-primary-600"
            fill="currentColor"
            viewBox="0 0 32 32"
          >
            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
          </svg>
        )}

        <div className="relative px-4">{children}</div>
        <footer className="mt-8 px-4">
          <div className="flex items-start">
            {authorPhotoUrl && (
              <div className="flex-shrink-0 inline-flex rounded-full border-2 border-white mr-4">
                <img
                  className="h-16 w-16 rounded-full"
                  src={authorPhotoUrl}
                  alt={authorName}
                />
              </div>
            )}
            <div className="pt-2">
              <div className="text-base font-bold">{authorName}</div>
              <div className="text-base font-medium text-gray-800">
                {authorTitle}
              </div>
            </div>
          </div>
        </footer>
      </div>
    </blockquote>
  );
}
