import Testimonial from "../../../../src/components/Testimonial";
import { testingAuditService } from "../../../../data/SiteConfig";
import { printGbpWithUsd } from "../../../../src/utils/formatting";
import ContactForm from "../../../../src/components/ContactForm";
import SimpleFaqList from "../../../../src/components/SimpleFaqList";
import Img from 'gatsby-image';
import * as React from 'react';
export default {
  Testimonial,
  testingAuditService,
  printGbpWithUsd,
  ContactForm,
  SimpleFaqList,
  Img,
  React
};