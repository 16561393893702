import React from 'react';

const SimpleFaqList = ({ faqs }) => (
  <div>
    {faqs.map((faq) => (
      <div key={faq.question}>
        <p className="font-bold italic mb-2">{faq.question}</p>
        {faq.answerHtml && <faq.answerHtml />}
        {!faq.answerHtml && <p>{faq.answer}</p>}
      </div>
    ))}
  </div>
);

export default SimpleFaqList;
