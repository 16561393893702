import React from 'react';

export default function EmailCourseSignupForm() {
    return (
      <div className="my-6">
        <script src="https://f.convertkit.com/ckjs/ck.5.js" async />
        <form action="https://app.convertkit.com/forms/1268404/subscriptions" className="seva-form formkit-form" method="post" data-sv-form="1268404" data-uid="54c5dd7ec9" data-version="5" data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;message&quot;,&quot;success_message&quot;:&quot;Almost done... Before receiving lesson 1, you need to check your email to confirm your subscription.&quot;,&quot;redirect_url&quot;:&quot;&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:false,&quot;url&quot;:&quot;https://convertkit.com/?utm_source=dynamic&amp;utm_medium=referral&amp;utm_campaign=poweredby&amp;utm_content=form&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;show&quot;,&quot;custom_content&quot;:&quot;&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}">
          <div data-style="clean">
            <ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert" />
            <div data-element="fields" data-stacked="false" className="seva-fields formkit-fields mx-auto text-center max-w-lg">
              <div className="formkit-field my-5">
                <label htmlFor="email_address" className="sr-only">Email address:</label>
                <input id="email_address" className="formkit-input w-full" name="email_address" placeholder="Your email address" required="required" type="email" />
              </div>
              <div className="my-5">
                <button data-element="submit" className="formkit-submit formkit-submit bg-primary-500 hover:bg-primary-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full" type="submit">
                  <div className="formkit-spinner">
                    <div />
                    <div />
                    <div />
                  </div>
                  <span>Send Lesson 1 Now</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
}
