/* eslint-disable import/prefer-default-export */
import siteConfig from '../../data/SiteConfig';

const { gbpToUsdRate } = siteConfig.exchangeRates;

export const printPrice = (
  price,
  currencySymbol = '£',
  currencyName = 'GBP',
  renderCurrencyName = true
) =>
  `${currencySymbol}${price.toLocaleString('en-gb')}${
    renderCurrencyName ? ` ${currencyName}` : ''
  }`;

export const printGbp = (price, renderCurrencyName = true) =>
  printPrice(price, '£', 'GBP', renderCurrencyName);

export const printUsd = (price, renderCurrencyName = true) =>
  printPrice(price, '$', 'USD', renderCurrencyName);

export const gbpToUsd = (priceGBP) => Math.round(priceGBP * gbpToUsdRate);

export const printGbpWithUsd = (priceGBP) => {
  const gbpFormatted = printGbp(priceGBP);
  const usdFormatted = printUsd(gbpToUsd(priceGBP));
  return `${gbpFormatted} (≈${usdFormatted})`;
};
