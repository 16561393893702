const config = {
  siteTitle: 'Serverless First', // Site title.
  siteTitleShort: 'SLS First', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'Winter Wind Software', // Alternative site title for SEO.
  siteLogo: '/img/logo/serverlessfirst-1200.png', // Logo used for SEO and manifest.
  siteUrl: 'https://serverlessfirst.com', // Domain of your website without pathPrefix
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    'Serverless application architecture and AWS cloud consulting', // Website description used for RSS feeds/meta description tag.
  siteRss: '/feed.xml', // Path to the RSS file.
  siteFBAppID: '', // FB Application ID for using app insights
  googleAnalyticsID: 'UA-45591994-3', // GA tracking ID.
  disqusShortname: 'winterwindsoftware', // Disqus shortname.
  rightMessageID: '412007828',
  postDefaultCategoryID: 'Serverless', // Default category for posts.
  dateFromFormat: 'YYYY-MM-DD', // Date format used in the frontmatter.
  dateFormat: 'DD/MM/YYYY', // Date format for display.
  postsPerPage: 50, // Amount of posts displayed per listing page.
  userFullName: 'Paul Swail',
  userName: 'paulswail', // Username to display in the author segment.
  userEmail: 'paul@winterwindsoftware.com', // Email used for RSS feed's author segment
  userTwitter: 'paulswail', // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: 'Belfast, N.Ireland', // User location to display in the author segment.
  userAvatar: '', // User avatar to display in the author segment.
  userDescription: '', // User description to display in the author segment.
  githubProfileUrl: 'https://github.com/paulswail/',
  twitterProfileUrl: 'https://twitter.com/paulswail',
  linkedInProfileUrl: 'https://www.linkedin.com/in/paulswail',
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: 'GitHub',
      url: 'https://github.com/paulswail/',
      iconClassName: 'fa fa-github',
    },
    {
      label: 'Twitter',
      url: 'https://twitter.com/paulswail',
      iconClassName: 'fa fa-twitter',
    },
    {
      label: 'Email',
      url: 'mailto:paul@winterwindsoftware.com',
      iconClassName: 'fa fa-envelope',
    },
  ],
  copyright: 'Copyright © 2021. Winter Wind Software Ltd', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#c62828', // Used for setting manifest and progress theme colors.
  backgroundColor: '#e0e0e0', // Used for setting manifest background color.
  workshopConfig: {
    nextWorkshopStartDate: 'May 6, 2021',
    nextWorkshopStartAndEndDate: 'May 6–27, 2021',
    nextWorkshopEarlybirdDeadline: 'April 29, 2021',
    nextWorkshopEarlybirdExpiryTime: '2021-04-30T05:00:00.000Z',
    extraInfo: '',
    meetingTimeMe: '4:00pm BST',
    meetingTimeUsEastern: '11:00am EDT',
    everyTimezoneLink: 'https://everytimezone.com/s/60f36596',
    meetingDayOfWeek: 'Thursday',
    meetingDuration: '90 minutes',
    numberOfWeeks: 4,
    earlybirdDiscountPercent: 30,
    salesOpen: false,
    activeCurrency: 'GBP',
    activeCurrencySymbol: '£',
    prices: {
      GBP: {
        selfStudy: 150,
        liveGroup: 600,
        livePersonal: 1500,
      },
    },
    signupUrls: {
      selfStudy: 'https://serverlessfirst.podia.com/testing-workshop/buy',
      liveGroup:
        'https://serverlessfirst.podia.com/testing-workshop-office-hours/buy',
      livePersonal:
        'https://serverlessfirst.podia.com/testing-workshop-consulting/buy',
    },
    earlybirdCouponCodes: {
      liveGroup: 'STW-EARLY-MAY2021',
      livePersonal: 'STW-EARLY-MAY2021',
    },
  },
  introCall: {
    bookingUrl: 'https://savvycal.com/paulswail/intro-call',
  },
  launchpadService: {
    priceGBP: 7000,
    applicationFormUrl: 'https://forms.gle/7rKJCfrd1j6Nm5Tm8',
  },
  callService: {
    priceGBP: 350,
    paymentLinkUrl: 'https://buy.stripe.com/eVa14509t8NBew8aEG',
    bookingUrl: 'https://savvycal.com/paulswail/consulting-call',
  },
  roadmapService: {
    priceGBP: 4500,
  },
  testingAuditService: {
    priceGBP: 2000,
  },
  architectureReviewService: {
    priceGBP: 5000,
  },
  guidanceService: {
    // standardPlan: { priceGBP: 3250 },
    // priorityPlan: { priceGBP: 5500 },
  },
  exchangeRates: {
    gbpToUsdRate: 1.22, // 2023-10-13
  },

  fathomConfig: {
    goals: {
      EMAIL_LIST_SUBSCRIBED: 'SOIZ4XDX',
      LAUNCHPAD_PV: 'BFLLG8V2',
      LAUNCHPAD_APPLY_START: '0T5Y0KDC',
    },
  },
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  config.pathPrefix = '';
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/')
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== '/')
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
