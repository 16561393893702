import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'gatsby';

export default function ContactForm({
  formName = 'contact',
  messageLabel = 'Tell me a bit more about how I could help you',
  messagePlaceholder = 'Your message',
  buttonText = 'Send',
  subject,
  hideSubject = false,
  showLinksOnSubmit = false,
}) {
  const [status, setStatus] = useState('INITIAL');
  const { register, handleSubmit, errors } = useForm({
    defaultValues: { subject },
  });
  const encode = (data) =>
    Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join('&');
  const onSubmit = async (data, event) => {
    event.preventDefault();

    setStatus('SUBMITTING');
    const { action } = event.target;
    try {
      await new Promise((resolve) => setTimeout(() => resolve(), 2000));
      await fetch(action, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': formName, ...data }),
      });
      setStatus('SUBMITTED');
    } catch (error) {
      console.log('Error submitting form', error);
      setStatus('SUBMISSION_ERROR');
    }
  };
  const inlineErrorRequired = (fieldName) => (
    <span className={`validation-message ${errors[fieldName] ? 'error' : ''}`}>
      This field is required
    </span>
  );
  const errorClass = (fieldName) => (errors[fieldName] ? 'error' : '');
  return (
    <div className="w-full">
      {status !== 'SUBMITTED' && (
        <form
          name={formName}
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          className="pb-8 mb-4 font-sans"
          noValidate
        >
          <input type="hidden" name="form-name" value={formName} />
          <div className="mb-2">
            <label className="block" htmlFor="name">
              Name *
            </label>
            <input
              className={`w-full ${errorClass('name')}`}
              name="name"
              type="text"
              placeholder="Your name"
              required
              ref={register({ required: true })}
            />
            {inlineErrorRequired('name')}
          </div>
          <div className="mb-2">
            <label className="block" htmlFor="email">
              Email *
            </label>
            <input
              className={`w-full ${errorClass('email')}`}
              name="email"
              type="email"
              placeholder="name@yourcompany.com"
              required
              ref={register({ required: true })}
            />
            {inlineErrorRequired('email')}
          </div>
          <div className="mb-2">
            <label className="block" htmlFor="website">
              Company URL
            </label>
            <input
              className={`w-full ${errorClass('website')}`}
              name="website"
              type="url"
              placeholder="https://www.yourcompany.com"
              ref={register({ required: false })}
            />
            {inlineErrorRequired('website')}
          </div>
          {!hideSubject && (
            <div className="mb-2">
              <label className="block" htmlFor="subject">
                Subject *
              </label>
              <input
                className={`w-full ${errorClass('subject')}`}
                name="subject"
                type="text"
                placeholder="e.g. Serverless Clarity Call"
                required
                ref={register({ required: true })}
              />
              {inlineErrorRequired('subject')}
            </div>
          )}
          <div className="mb-2">
            <label className="block" htmlFor="message">
              {messageLabel} *
            </label>
            <textarea
              className={`w-full ${errorClass('message')}`}
              name="message"
              rows="10"
              placeholder={messagePlaceholder}
              required
              ref={register({ required: true })}
            />
            {inlineErrorRequired('message')}
          </div>
          {status === 'SUBMISSION_ERROR' && (
            <div className="text-center text-red-700 bg-red-200 rounded p-2 mb-8">
              An error occurred sending your message. ☹️
              <br />
              Please try again shortly.
            </div>
          )}
          <div className="flex items-center justify-between">
            <button
              className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              disabled={status === 'SUBMITTING'}
              type="submit"
            >
              {status !== 'SUBMITTING' && <span>{buttonText}</span>}
              {status === 'SUBMITTING' && <span>Sending...</span>}
            </button>
          </div>
        </form>
      )}

      {status === 'SUBMITTED' && (
        <div className="text-center">
          <h3>✅ Thank you for your message!</h3>
          <p>I will be in contact as soon as possible.</p>
          {showLinksOnSubmit && (
            <p>
              <Link to="/">Go back to home page</Link> or{' '}
              <Link to="/articles/">read some serverless articles</Link>.
            </p>
          )}
        </div>
      )}
    </div>
  );
}
