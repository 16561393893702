// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-case-studies-3-advance-serverless-transformation-mdx": () => import("./../../../src/pages/case-studies/3advance-serverless-transformation.mdx" /* webpackChunkName: "component---src-pages-case-studies-3-advance-serverless-transformation-mdx" */),
  "component---src-pages-case-studies-autotrader-mdx": () => import("./../../../src/pages/case-studies/autotrader.mdx" /* webpackChunkName: "component---src-pages-case-studies-autotrader-mdx" */),
  "component---src-pages-case-studies-bbc-discover-mdx": () => import("./../../../src/pages/case-studies/bbc-discover.mdx" /* webpackChunkName: "component---src-pages-case-studies-bbc-discover-mdx" */),
  "component---src-pages-case-studies-bbc-rewind-mdx": () => import("./../../../src/pages/case-studies/bbc-rewind.mdx" /* webpackChunkName: "component---src-pages-case-studies-bbc-rewind-mdx" */),
  "component---src-pages-case-studies-bluesky-mdx": () => import("./../../../src/pages/case-studies/bluesky.mdx" /* webpackChunkName: "component---src-pages-case-studies-bluesky-mdx" */),
  "component---src-pages-case-studies-jsx": () => import("./../../../src/pages/case-studies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-emails-jsx": () => import("./../../../src/pages/emails.jsx" /* webpackChunkName: "component---src-pages-emails-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-newsletter-jsx": () => import("./../../../src/pages/newsletter.jsx" /* webpackChunkName: "component---src-pages-newsletter-jsx" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-referral-jsx": () => import("./../../../src/pages/referral.jsx" /* webpackChunkName: "component---src-pages-referral-jsx" */),
  "component---src-pages-services-call-mdx": () => import("./../../../src/pages/services/call.mdx" /* webpackChunkName: "component---src-pages-services-call-mdx" */),
  "component---src-pages-services-guidance-mdx": () => import("./../../../src/pages/services/guidance.mdx" /* webpackChunkName: "component---src-pages-services-guidance-mdx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-services-launchpad-jsx": () => import("./../../../src/pages/services/launchpad.jsx" /* webpackChunkName: "component---src-pages-services-launchpad-jsx" */),
  "component---src-pages-services-review-mdx": () => import("./../../../src/pages/services/review.mdx" /* webpackChunkName: "component---src-pages-services-review-mdx" */),
  "component---src-pages-services-roadmap-mdx": () => import("./../../../src/pages/services/roadmap.mdx" /* webpackChunkName: "component---src-pages-services-roadmap-mdx" */),
  "component---src-pages-services-testing-audit-mdx": () => import("./../../../src/pages/services/testing-audit.mdx" /* webpackChunkName: "component---src-pages-services-testing-audit-mdx" */),
  "component---src-pages-subscribed-jsx": () => import("./../../../src/pages/subscribed.jsx" /* webpackChunkName: "component---src-pages-subscribed-jsx" */),
  "component---src-pages-testimonials-jsx": () => import("./../../../src/pages/testimonials.jsx" /* webpackChunkName: "component---src-pages-testimonials-jsx" */),
  "component---src-pages-transition-to-serverless-mdx": () => import("./../../../src/pages/transition-to-serverless.mdx" /* webpackChunkName: "component---src-pages-transition-to-serverless-mdx" */),
  "component---src-pages-workshops-testing-jsx": () => import("./../../../src/pages/workshops/testing.jsx" /* webpackChunkName: "component---src-pages-workshops-testing-jsx" */),
  "component---src-templates-email-jsx": () => import("./../../../src/templates/email.jsx" /* webpackChunkName: "component---src-templates-email-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

